export enum PermissionEnum {
  CREATE_PRODUCT = 'Create_Product',
  READ_PRODUCT = 'Read_Product',
  UPDATE_PRODUCT = 'Update_Product',
  DELETE_PRODUCT = 'Delete_Product',

  CREATE_PRODUCT_DATA = 'Create_Product-Data',
  READ_PRODUCT_DATA = 'Read_Product-Data',
  UPDATE_PRODUCT_DATA = 'Update_Product-Data',
  DELETE_PRODUCT_DATA = 'Delete_Product-Data',

  CREATE_TRANSLATION = 'Create_Translation',
  READ_TRANSLATION = 'Read_Translation',
  UPDATE_TRANSLATION = 'Update_Translation',
  DELETE_TRANSLATION = 'Delete_Translation',

  CREATE_LANGUAGE = 'Create_Language',
  READ_LANGUAGE = 'Read_Language',
  UPDATE_LANGUAGE = 'Update_Language',
  DELETE_LANGUAGE = 'Delete_Language',

  CREATE_COMPANY = 'Create_Company',
  READ_COMPANY = 'Read_Company',
  UPDATE_COMPANY = 'Update_Company',
  DELETE_COMPANY = 'Delete_Company',

  CREATE_USER = 'Create_User',
  READ_USER = 'Read_User',
  UPDATE_USER = 'Update_User',
  DELETE_USER = 'Delete_User',

  CREATE_ROLE = 'Create_Role',
  READ_ROLE = 'Read_Role',
  UPDATE_ROLE = 'Update_Role',
  DELETE_ROLE = 'Delete_Role',

  CREATE_ATTRIBUTE = 'Create_Attribute',
  READ_ATTRIBUTE = 'Read_Attribute',
  UPDATE_ATTRIBUTE = 'Update_Attribute',
  DELETE_ATTRIBUTE = 'Delete_Attribute',

  CREATE_ATTRIBUTE_GROUP = 'Create_AttributeGroup',
  READ_ATTRIBUTE_GROUP = 'Read_AttributeGroup',
  UPDATE_ATTRIBUTE_GROUP = 'Update_AttributeGroup',
  DELETE_ATTRIBUTE_GROUP = 'Delete_AttributeGroup',

  CREATE_ORDER = 'Create_Order',
  READ_ORDER = 'Read_Order',
  UPDATE_ORDER = 'Update_Order',
  DELETE_ORDER = 'Delete_Order',

  CREATE_ORDER_SELLER = 'Create_Order-Seller',
  READ_ORDER_SELLER = 'Read_Order-Seller',
  UPDATE_ORDER_SELLER = 'Update_Order-Seller',
  DELETE_ORDER_SELLER = 'Delete_Order-Seller',

  READ_ORDER_RESELLER = 'Read_Order-Reseller',
  READ_PRODUCT_RESELLER = 'Read_Product-Reseller',

  CREATE_PRODUCT_SELLER = 'Create_Product-Seller',
  READ_PRODUCT_SELLER = 'Read_Product-Seller',
  UPDATE_PRODUCT_SELLER = 'Update_Product-Seller',
  DELETE_PRODUCT_SELLER = 'Delete_Product-Seller',

  CREATE_SELLER = 'Create_Seller',
  READ_SELLER = 'Read_Seller',
  UPDATE_SELLER = 'Update_Seller',
  DELETE_SELLER = 'Delete_Seller',

  CREATE_BRAND = 'Create_Brand',
  READ_BRAND = 'Read_Brand',
  UPDATE_BRAND = 'Update_Brand',
  DELETE_BRAND = 'Delete_Brand',

  READ_CATEGORY = 'Read_Category',
  CREATE_CATEGORY = 'Create_Category',
  UPDATE_CATEGORY = 'Update_Category',
  DELETE_CATEGORY = 'Delete_Category',

  CREATE_PERMISSION = 'Create_Permission',
  READ_PERMISSION = 'Read_Permission',
  UPDATE_PERMISSION = 'Update_Permission',
  DELETE_PERMISSION = 'Delete_Permission',
}
